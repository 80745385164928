import { useState } from "react";
import { createContext, useContext, useContextSelector } from "use-context-selector";

const RecipeSidebarContext = createContext({});
/**
 * A hook that will return inner and outer height and width values whenever
 * the window is resized.
 *
 * @kind function
 * @private
 */
const useRecipeSidebarContextVals = (defaults) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarSection, setSidebarSection] = useState("ingredients");
  // const sizes = useMemo(() => {
  //   //when the width changes, update the sizes
  //   return Object.keys(theme.theme.extend.screens).reduce((acc, key) => {
  //     const value = theme.theme.extend.screens[key];
  //     const size = value.replace("px", "");
  //     acc[key] = size.width >= size;
  //     return acc;
  //   }, {});
  // }, [size.width]);

  const openRecipeSidebar = () => {
    setSidebarOpen(true);
  };

  const closeRecipeSidebar = () => {
    setSidebarOpen(false);
  };

  return {
    sidebarOpen,
    setSidebarOpen,
    sidebarSection,
    setSidebarSection,
    openRecipeSidebar,
    closeRecipeSidebar,
  };
};

const RecipeSidebarContextProvider = (props) => {
  // This hook has side effects of adding listeners so we only want to create it
  // once and store it in context for reference by components.
  const recipesidebarContext = useRecipeSidebarContextVals(props.defaults);

  return <RecipeSidebarContext.Provider value={{ ...recipesidebarContext }}>{props.children}</RecipeSidebarContext.Provider>;
};

/**
 * The current context value for the window size context.
 * This value updates whenever the window is resized.
 *
 * Use this inside a {@link WindowSizeContextProvider}.
 *
 * @type number
 */
const useRecipeSidebarContext = () => useContext(RecipeSidebarContext);
const useRecipeSidebarSelectorContext = (selector: any) => {
  return useContextSelector(RecipeSidebarContext, selector);
};
export { RecipeSidebarContextProvider, useRecipeSidebarContext, useRecipeSidebarSelectorContext };
