import List from "antd-mobile/es/components/list";
import SwipeAction from "antd-mobile/es/components/swipe-action";
import Tag from "antd-mobile/es/components/tag";
import Toast from "antd-mobile/es/components/toast";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import { toTitleCase } from "~/utils/misc";
dayjs.extend(calendar);

export default function RecipePlan({ leftActions, rightActions, date, meal, totalTime, time, servings, recipeName, placeholder, ...props }) {
  const datetimeParseable = time !== "Invalid Date" ? `${date} ${time}` : date;
  const timeIsValid = time !== "Invalid Date";
  const title = recipeName
    ? recipeName
    : `${placeholder ? "Now - " : ""}${dayjs(`${datetimeParseable}`).calendar(undefined, {
      sameDay: "[Today]",
      lastDay: "[Yesterday]",
      lastWeek: "[Last] dddd",
      sameElse: "ddd, MMM D",
      nextWeek: "dddd",
      nextDay: "[Tomorrow]",
    })}`;

  const subtitle =
    recipeName && timeIsValid
      ? `eat at ${dayjs(`${datetimeParseable}`).format("h:mm A")}`
      : time && totalTime && timeIsValid
        ? `start cooking at ${dayjs(`${datetimeParseable}`).subtract(totalTime, "m").format("h:mm A")}`
        : null;
  return (
    <SwipeAction leftActions={leftActions} rightActions={rightActions}>
      <List.Item
        className="text-lg"
        // style={{ "--padding-left": "0px" }}
        onClick={() => {
          Toast.show("Swipe Right to Delete");
        }}
        // extra={date.meal}
        //dayjs subtracts the total time from the date
        description={subtitle}
        {...props}
        extra={
          <div className="flex flex-col items-end">
            <div>
              <Tag round>{servings}</Tag>
            </div>
            <div>{meal && toTitleCase(meal)}</div>
          </div>
        }
      >
        {title}
      </List.Item>
    </SwipeAction>
  );
}
